import Glide from '@glidejs/glide'

const myGlide = new Glide('.glide', {
	type: 'slider',
	perView: 6,
	gap: 60,
	perSwipe: '|',
}).mount()

myGlide.on(['mount.before'], function () {
	const bulletCount = myGlide.settings.perView;
	const bulletWrapper = document.querySelectorAll('.glide__bullets')[0];
	for (let index = 0; index < bulletCount; index++) {
		const button = document.createElement('button');
		button.className = 'glide__bullet';
		button.setAttribute("data-glide-dir", '=' + (index * 6));
		bulletWrapper.appendChild(button);
	}
});

// Hiding them with d-none if it is needed
myGlide.on(['resize', 'build.after'], function () {
	const perView = myGlide.settings.perView;
	const total = document.querySelectorAll('.glide__slide').length;
	const sub = total / perView;

	document.querySelectorAll('.glide__bullet').forEach((el, i) => {
		if (i >= sub) {
			el.classList.add("d-none");
		} else {
			el.classList.remove("d-none");
		}
	});

	if (myGlide.index > sub) {
		myGlide.go('=' + sub);
	}
});

myGlide.mount().update();

domReady(() => {
	let parentCategories = document.querySelectorAll('.toggle-categories li.parent a');

	Array.from(parentCategories).forEach(parentCategory => {
		parentCategory.addEventListener('click', function(e) {
			e.preventDefault();
			let target = e.target.getAttribute('href');
			let targetEl = document.getElementById(target);

			if (typeof(targetEl) != 'undefined' && targetEl != null) {
				document.querySelector('.toggle-categories li.active').classList.remove('active');
				e.target.parentElement.classList.add('active');

				let categoriesCards = document.querySelectorAll('.categories-cards');
				Array.from(categoriesCards).forEach(categoriesCard => {
					categoriesCard.style.display = 'none';
				});

				targetEl.style.display = 'flex';
			}
		});
	});
});

function domReady(fn) {
	// If we're early to the party
	document.addEventListener('DOMContentLoaded', fn);
	// If late; I mean on time.
	if (document.readyState === 'interactive' || document.readyState === 'complete' ) {
		fn();
	}
}
